import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import select2 from "select2";

Vue.use(TurbolinksAdapter);

document.addEventListener("turbolinks:load", function () {
  var element = document.querySelector(".webinar-vue-form");
  if (element) {
    var app = new Vue({
      el: element,
      data: {
        newCategory: false,
      },
      methods: {
        afterEnter: function (el) {
          jQuery(".search-select", el).select2();
          jQuery(".datepicker", el).flatpickr({
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            locale: "it",
          });
        },
      },
    });
  }
});
