import select2 from "select2";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";
import videojs from "video.js";

document.addEventListener("turbolinks:before-cache", function () {
  $(
    ".search-select, .search-user-state, .search-webinar-state, .search-webinar-attendee-state"
  ).select2("destroy");
  try {
    $(".datetimepicker").flatpickr().destroy();
  } catch (error) {
    // console.error(error);
  }
  try {
    $(".datepicker").flatpickr().destroy();
  } catch (error) {
    // console.error(error);
  }

  document.querySelectorAll("video-js").forEach((element) => {
    var video = videojs(element);
    video.dispose();
  });
});

document.addEventListener("turbolinks:load", function () {
  $(".search-select").select2();

  $(".search-user-state").select2({
    placeholder: "Tipologia utente",
    templateSelection: function (selection, container) {
      if (selection.selected) {
        switch (selection.id) {
          case "member":
            container.addClass("bg-primary text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "guest":
            break;
          case "to_be_approved":
            container.addClass("bg-danger text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "admin":
            container.addClass("bg-success text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "registered":
            container.addClass("bg-dark text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
        }
        return $.parseHTML(
          '<span class="im_selected">' + selection.text + "</span>"
        );
      } else {
        return $.parseHTML(
          '<span class="im_writein">' + selection.text + "</span>"
        );
      }
    },
  });

  $(".search-webinar-state").select2({
    placeholder: "Stato",
    templateSelection: function (selection, container) {
      if (selection.selected) {
        switch (selection.id) {
          case "open":
            container.addClass("bg-warning");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "guest":
            break;
          case "draft":
            container.addClass("bg-secondary text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "finished":
            container.addClass("bg-success text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "closed":
            container.addClass("bg-dark text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
        }
        return $.parseHTML(
          '<span class="im_selected">' + selection.text + "</span>"
        );
      } else {
        return $.parseHTML(
          '<span class="im_writein">' + selection.text + "</span>"
        );
      }
    },
  });

  $(".search-webinar-attendee-state").select2({
    placeholder: "Status",
    templateSelection: function (selection, container) {
      if (selection.selected) {
        switch (selection.id) {
          case "payment_confirmed":
            container.addClass("bg-warning");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "guest":
            break;
          case "waiting_for_payment":
            container.addClass("bg-danger text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "waiting_for_test":
            container.addClass("bg-info text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "finished":
            container.addClass("bg-success text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
          case "cancelled":
            container.addClass("bg-dark text-light");
            container
              .find(".select2-selection__choice__remove")
              .addClass("text-light");
            break;
        }
        return $.parseHTML(
          '<span class="im_selected">' + selection.text + "</span>"
        );
      } else {
        return $.parseHTML(
          '<span class="im_writein">' + selection.text + "</span>"
        );
      }
    },
  });

  flatpickr(".datepicker", {
    altInput: true,
    altFormat: "d/m/Y",
    dateFormat: "Y-m-d",
    locale: "it",
  });

  flatpickr(".datetimepicker", {
    altInput: true,
    altFormat: "d/m/Y H:i",
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    locale: "it",
    time_24hr: true,
  });

  $("form .submit-on-change").on("change", function (event) {
    $(this).closest("form").submit();
  });

  $("form .submit-on-enter").keydown(function (event) {
    if (event.keyCode == 13) {
      $(this).closest("form").submit();
    }
  });

  $(".no-propagation").click(function (e) {
    e.stopPropagation();
  });

  $(".webinar-exam-search-select").on("change", function (event) {
    $.get({
      url:
        "/admin/exams/" +
        $(this).val() +
        "/preview-partial?redirect_url=" +
        $("#update_redirect_path").val(),
      success: function (data) {
        $("#exam-preview").html(data);
      },
    });
  });

  saveTabSelection("#webinars-tab-select", "activeWebinarsTab");

  document.querySelectorAll("video-js").forEach((element) => {
    videojs(element);
  });
});

function saveTabSelection(parent, property) {
  $(parent + ' button[data-toggle="tab"]').on("show.bs.tab", function (e) {
    localStorage.setItem(property, $(e.target).attr("data-target"));
  });

  var activeWebinarsTab = localStorage.getItem(property);
  if (activeWebinarsTab) {
    $(parent + ' button[data-target="' + activeWebinarsTab + '"]').tab("show");
  }
}
