import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import select2 from "select2";

Vue.use(TurbolinksAdapter);

document.addEventListener("turbolinks:load", function () {
  var element = document.querySelector(".edit-user-company-form");
  if (element) {
    var formData = JSON.parse(element.attributes.data.value);
    if (element.attributes.company) {
      var company_values = JSON.parse(element.attributes.company.value);
    } else {
      var company_values = false;
    }
    var app = new Vue({
      el: element,
      data: {
        form: formData,
        company: company_values,
      },
      methods: {
        updateCompanyParams: function () {
          axios
            .get("/admin/companies/" + this.form.company_id + ".json")
            .then((response) => (this.company = response.data));
        },
      },
    });
  }
});
