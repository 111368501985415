import { Controller } from "stimulus";
import axios from "axios";
import videojs from "video.js";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["activePlayer", "videoDebug", "currentTime", "apiUpdate"];
  interval = null;

  connect() {
    if (this.hasActivePlayerTarget) {
      var video = videojs(this.activePlayerTarget);
      video.ready(() => {
        if (this.data.has("startAt")) {
          console.log("start at " + this.data.get("startAt"));
          video.currentTime(this.data.get("startAt"));
        }
        this.interval = setInterval(() => {
          var currentTime = parseInt(video.currentTime());
          if (currentTime > 0) {
            this.updateAttendee(currentTime);
          }
          if (this.hasCurrentTimeTarget) {
            this.currentTimeTarget.innerHTML = currentTime;
          }
        }, 5000);
      });
      video.on("ended", () => {
        if (this.interval) {
          window.clearInterval(this.interval);
        }
        var currentTime = parseInt(video.currentTime());
        this.updateAttendee(currentTime, true);
        if (this.hasCurrentTimeTarget) {
          this.currentTimeTarget.innerHTML = "ended";
        }
      });
    }
  }

  disconnect() {
    if (this.interval) {
      window.clearInterval(this.interval);
      console.log("cleared");
    }
  }

  updateAttendee(currentTime, complete = false) {
    var attendeeId = this.data.get("attendeeId");
    var courseId = this.data.get("courseId");
    if (attendeeId && attendeeId != "") {
      const token = document.querySelector("[name=csrf-token]").content;

      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .post("/course/video/attendee/update", {
          id: courseId,
          attendee: attendeeId,
          complete: complete,
          current_timestamp: currentTime,
        })
        .then((response) => {
          if (this.hasApiUpdateTarget) {
            this.apiUpdateTarget.innerHTML = "ok " + this.getDateTime();
          }
        })
        .catch((error) => {
          if (this.hasApiUpdateTarget) {
            this.apiUpdateTarget.innerHTML = "error " + this.getDateTime();
          }
        });
    }
  }

  getDateTime() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    return dateTime;
  }
}
